<template>
  <div style="padding:30px;">
    <el-alert
      :closable="false"
      title="menu 1-1"
      type="success"
    >
      <router-view />
    </el-alert>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({

})
</script>
